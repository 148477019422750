/* //App.js */
.App {
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
}

/* //Header */
.smaller img {
  width: 17px;
}

.bold {
  font-weight: bold !important;
  font-size: 22px !important;
}

.headerSizing {
  margin-top: 20px;
  color: white;
  z-index: 1000; /* Add this line */
}

.headerContainer {
  height: 75px;
  border-radius: 25px;
  padding: 20px;
  width: 200px;
  height: 60px;
  box-shadow: 6px 6px 24px 0 rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  overflow: hidden !important;
  background-color: white;
}
.custom-dropdown-toggle {
  text-transform: none !important; /* No capitalization */
  color: rgb(0 0 0 / 55%) !important; /* Change text color to black */
  background-color: transparent !important; /* Change background color to transparent */
  border-color: transparent !important; /* If there's a border, make it transparent */
}

/* Remove the dropdown arrow */
.custom-dropdown-toggle::after {
  display: none !important;
}

/* //////////////// */

/* //Homepage */
.container {
  max-width: 85% !important;
}

.textCenter {
  text-align: center;
}
/* //Section1 */
.topHeader {
  margin-bottom: 0px;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-color: #c0c0c0;
}
.firstHeader {
  font-weight: bold !important;
  font-size: 60px !important;
}

.topheaderContainer {
  height: 20px;
}

.btn2 {
  text-decoration: none !important;
  border: none !important;
  white-space: nowrap;
  margin-left: 10px;
  width: 150px;
  padding: 1px 15px 1px 15px !important;
  width: 150px;
  background-color: #f1e9e9 !important;
}
.bt2a {
  width: 400px;
  text-align: center;
  color: white;
}
.custom-button {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  padding: 10px 20px; /* Adjust padding as needed */
}

.custom-button-link {
  width: auto; /* Let the width adjust based on content */
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 1rem; /* Adjust the font size to fit within the button */
  text-decoration: none; /* Remove the underline from the link */
}

.apply-now-btn {
  width: 150px;
  text-align: center;
  color: white;
  text-decoration: none;
}

/* //section2 */
.homepageNurseColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepageNurse {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.threeReasonRowPadding,
.padding {
  padding-left: 130px;
  padding-right: 130px;
  padding-top: 30px;
  padding-bottom: 55px;
  margin-top: 50px;
}

/* // extra benefits // */

.extraBenefits {
  padding: 30px;
}
.extraBenefitsRow {
  height: 430px;
}

.lastSection {
  border-radius: 15px 50px 30px;
  background: #aed2ea;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 80px;
  height: 300px;
}

/* section 3 */
.custom-dropdown {
  color: #0c0c0c; /* Or any color you prefer */
  text-decoration: none;
}

/* //////////////////// */

/* //ABOUT US PAGE */
.tiredPic {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.icons {
  text-align: center;
}

.mainParentFooter {
  margin-top: 30px;
  margin-bottom: 20px;
}

.grpPhoto {
  border-radius: 15px 50px 30px;
  background: #73ad21;
  padding: 20px;
  width: auto;
  height: 400px;
}

.prblms {
  flex-basis: 50%;
}

.prblms img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 15px 50px 30px;
}

.prblms-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* //////////////////// Services */
.values {
  border-radius: 15px 50px !important;

  padding: 20px;
  width: 200px;
  height: 150px;
}

/* /////////////////// */
FOOTER .parentFooterLinks {
  display: flex;
  text-align: center;
}
.footerLinks {
  text-decoration: none;
  flex-grow: 3;
  color: black;
}
.footerMargin {
  padding: 20px 0px 0px;
  height: 210px;
  margin-top: 40px;
}

.Footer {
  margin-top: auto;
}

html {
  height: 100%;
}

.copyright {
  display: inline-flex;
  font-size: smaller;
  justify-content: center;
  width: 1053px !important;
}
.parentFooter2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  list-style: none;
}
.footerIcon {
  width: "32px";
  height: "32px";
}

/* //////employee card */
.card-container {
  width: 250px; /* or whatever you prefer */
  height: 350px; /* or whatever you prefer */
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s; /* adjust duration as desired */
  perspective: 1000px; /* gives a nice 3D effect */
}

.card-container:hover {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* hides the side that is not facing the user */
}

.card-front {
  /* the front does not need any special styling for flipping */
}

.card-back {
  transform: rotateY(180deg);
}
